import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import Blog from '../components/Blog'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query SanityBlog {
    sanityBlog(title: {eq: "blog"}) {
      title
      _rawBody
    }
  }
`

const BlogPage = props => {
  const {data, errors} = props
  const {title} = data.sanityBlog
  const post = data && data.sanityBlog
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO title='info' />
      <Container>
        <h1 className={responsiveTitle1}>{ title }</h1>  
        {post && <Blog {...post} />}
      </Container>
    </Layout>
  )
}

export default BlogPage
